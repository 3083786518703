<template>
	<div style="overflow: hidden;">
		<div class="pcBox">
			<home-header index="3"  :allColorW="true"></home-header>
			<div class="homeContent">
				<div class="secureTitle">{{$t('secureChat.anquanchengaji')}}</div>
				<div class="secureContent">
					<el-image class="secureBg" :src="require('@/images/home2/secureChatRow1.png')"></el-image>
					<div  class="secureTextBox">
						<div class="secureTextTitle">
							<el-image class="secureIcon1" :src="require('@/images/home2/secureChatIcon1.png')"></el-image>
							<div class="secureText">{{$t('secureChat.sidahexin')}}</div>
						</div>
						<div class="secureTextItemBox">
							<div class="secureTextItem" v-for="i in 4">
								<div class="blueIcon"></div>
								<div class="secureText">{{ $t(`secureChat.Safetymeasures${i}`) }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="secureTitle">{{$t('secureChat.jiamifangshi')}}</div>
				<div class="secureTabs">
					<div class="secureTabsItem " v-for="(item, index) in 3" :key="index">
						<div class="secureTabsBtn" :class="tabsIndex==index?'secureTabsItem_a':''" @click="changeTabs(index)">
							{{ $t(`secureChat.tabs${item}`) }}
						</div>
					</div>
				</div>

				<div class="secureTabsContent1" v-if="tabsIndex==0">
					<div class="secureTabsContent1L">
						<div class="secureTabsContent1LTitleM">{{$t('secureChat.tongdaojiami')}}</div>
						<div class="secureTabsContent1LTitleS">
							<el-image class="secureTabsContent1LTitleSIcon" :src="require('@/images/home2/secureChatIcon2.png')"></el-image>
							<div>{{$t('secureChat.jiagoutu')}}</div>
						</div>
						<div class="secureTabsContent1LText">{{$t('secureChat.jimiyongdaode')}}</div>

						<div class="secureTabsContent1LTitleS">
							<el-image class="secureTabsContent1LTitleSIcon" :src="require('@/images/home2/secureChatIcon3.png')"></el-image>
							<div>{{$t('secureChat.buzhou')}}</div>
						</div>
						<div class="secureTabsContent1LText">
							
							<div v-for="i in 21">{{$t(`secureChat.buzhou${i}`)}}</div>
							

						</div>
					</div>
					<div class="secureTabsContent1R">
						<el-image class="secureTabsContent1RImg" :src="require(`@/images/home2/secureChatImg1${'_'+lang}.png`)"></el-image>
					</div>
				</div>

				<div class="secureTabsContent2" v-if="tabsIndex==1">
					<div class="secureTabsContent2Item" :class="lang=='en'?'secureTabsContent2ItemEn':''">
						<div class="secureTabsContent2ItemTitle">
							<el-image class="secureTabsContent2ItemIcon" :src="require('@/images/home2/secureChatIcon6.png')"></el-image>
							<div class="secureTabsContent2ItemTitleText">{{$t('secureChat.jiami')}}</div>
						</div>
						<div class="secureTabsContent2ItemText">
							<div v-for="i in 5">{{$t(`secureChat.jiami${i}`)}}<br></div>

							
						</div>
						<el-image class="secureTabsContent2ItemImg" :src="require(`@/images/home2/secureChatImg2${'_'+lang}.png`)"></el-image>
					</div>
					<div class="secureTabsContent2Item" :class="lang=='en'?'secureTabsContent2ItemEn':''">
						<div class="secureTabsContent2ItemTitle">
							<el-image class="secureTabsContent2ItemIcon" :src="require('@/images/home2/secureChatIcon7.png')"></el-image>
							<div class="secureTabsContent2ItemTitleText">{{$t('secureChat.shuangji')}}</div>
						</div>
						<div class="secureTabsContent2ItemText">
							{{$t('secureChat.shuangji1')}}
						</div>
						<el-image class="secureTabsContent2ItemImg" :src="require(`@/images/home2/secureChatImg3${'_'+lang}.png`)"></el-image>
					</div>
				</div>
				<div class="secureTabsContent3" v-if="tabsIndex==2">
					<div class="secureTabsContent3Item">
						<el-image class="secureTabsContent3ItemBg" :src="require('@/images/home2/secureTabsContent3Bg.png')"></el-image>
						<div class="secureTabsContent3Text">
							<div class="secureTabsContent3ItemTitle">
								<el-image class="secureTabsContent3ItemIcon" :src="require('@/images/home2/secureChatIcon8.png')"></el-image>
								<div class="secureTabsContent3ItemTitleText">{{$t('secureChat.shujukuanq')}}</div>
							</div>
							<div class="secureTabsContent3Text">
								<div class="secureTabsContent3TextRow">{{$t('secureChat.fangchuangai')}}</div>
								<div class="secureTabsContent3TextRow" v-for="i in 5">
									<div class="blueIcon"></div>
									<div style="max-width: 570px;">{{$t(`secureChat.fangchuangai${i}`)}}</div>
								</div>
								
							</div>
						</div>
					</div>

					<div class="secureTabsContent3Item">
						<el-image class="secureTabsContent3ItemBg" :src="require('@/images/home2/secureTabsContent3Bg.png')"></el-image>
						<div class="secureTabsContent3Text">
							<div class="secureTabsContent3ItemTitle">
								<el-image class="secureTabsContent3ItemIcon" :src="require('@/images/home2/secureChatIcon9.png')"></el-image>
								<div class="secureTabsContent3ItemTitleText">{{$t('secureChat.miyaoanquan')}}</div>
							</div>
							<div class="secureTabsContent3Text">
								<div class="secureTabsContent3TextRow" v-for="i in 3">
									<div class="blueIcon"></div>
									<div style="max-width: 570px;">{{$t(`secureChat.miyaoanquan${i}`)}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div class="secureTitle">{{$t('secureChat.gongyaosiyao')}}</div>
				<div class="secureContent secureContent2">
					<el-image class="secureBg" :src="require('@/images/home2/secureChatRow2.png')"></el-image>
					<div  class="secureTextBox2">
						<div class="secureTextTitle">
							<el-image class="secureIcon1" :src="require('@/images/home2/secureChatIcon4.png')"></el-image>
							<div class="secureText">{{$t('secureChat.erdamiyao')}}</div>
						</div>
						<div class="secureTextItemBox2">
							{{$t('secureChat.gongyaosiyaoxiangqing')}}
						</div>
					</div>
				</div>
				<div class="secureTitle">{{$t('secureChat.yuanli')}}</div>
				<div class="secureContent secureContent2">
					<el-image class="secureBg" :src="require('@/images/home2/secureChatRow3.png')"></el-image>
					<div  class="secureTextBox2">
						<div class="secureTextTitle">
							<el-image class="secureIcon1" :src="require('@/images/home2/secureChatIcon5.png')"></el-image>
							<div class="secureText">{{$t('secureChat.yuanlijiexi')}}</div>
						</div>
						<div class="secureTextItemBox2" style="display: flex;flex-direction: column;">
							<div v-for="i in 5">{{$t(`secureChat.yuanli${i}`)}}</div>

						
						</div>
					</div>
				</div>
			</div>
			<home-foot></home-foot>

		</div>
		<div class="mobileBox">
			<mobile-main :index="pageindex"></mobile-main>

		</div>
	</div>
</template>
<script>
import HomeHeader from "./components/HomeHeader"
import HomeFoot from "./components/HomeFoot"
import MobileMain from "./components/MobileMain"
import HomeNews from "./components/HomeNews"


export default {
	components: {
		HomeHeader, 
		HomeNews,
		HomeFoot,
		MobileMain
	},                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
	data() {
		return {
			pageindex:'1',
			pageType:1,
			tabsIndex:0,
			lang:this.$i18n.locale,
		}
	},
	mounted(){
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		this.pageType = this.getQueryVariable('pageType')
		document.title = '安全聊天 - 事密达'
	},
	watch: {
		'$i18n.locale'(newLocale, oldLocale) {
		// 在语言切换时执行逻辑
			this.lang = this.$i18n.locale;
		},
	},
	methods:{
		changeTabs(index){
			this.tabsIndex =index
		},
		//获取url参数
		getQueryVariable(value) {
			var query = window.location.search.substring(1);
			query = query.split("&");

			var data = {};

			for (var i = 0; i < query.length; i++) {
				var item = query[i].split("=");
				data[item[0]] = item[1]
			}

			if (value) {
				return data[value] ? data[value] : '';
			}

			return data;
		},
	},
};

</script>
<style scoped>


.color {
	background-color: #2d3a4b;

}

/* 主区域 */
.main {

	/* 当内容过多时y轴出现滚动条 */
	/* background-color: red; */
}

.mobileBox{
	display: none;
}
@media screen and (max-width:750px) {
	.pcBox{
		display: none;
	}
	.mobileBox{
		display: block;
	}
}
.homeContent{
	padding-top: 10.8vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-bottom: 140px;
}
.secureTitle{
	font-size: 28px;
	font-weight: 600;
	color: #01071A;
	line-height: 40px;
	margin: 24px 0 32px;
	width: 1440px;	
}
.secureContent{
	width: 1440px;
	height: 153px;
	position: relative;
	margin-bottom: 24px;
}
.secureContent2{
	height: 155px;
}
.secureTextBox2{
	position: relative;
	z-index: 2;
	padding: 32px 32px;
}
.secureTextItemBox2{
	display: flex;
	margin-top: 11px;
}
.secureBg{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 1440;
}
.secureTextBox{
	position: relative;
	z-index: 2;
	padding: 28px 30px;
}
.secureIcon1{
	width: 32px;
	height: 32px;
	margin-right: 15px;
}
.secureTextTitle{
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: 600;
	color: #01071A;
	line-height: 28px;
}
.secureTextItemBox{
	display: flex;
	margin-top: 32px;
}
.secureTextItem{
	display: flex;
	align-items: center;
	margin-right: 56px;
}
.blueIcon{
	width: 8px;
	height: 8px;
	background: #3486FF;
	margin-right: 12px;
	border-radius: 60px;
}
.secureTabs{
	width: 1440px;
	border-bottom: 1px solid #E5E9EF;
}
.secureTabsItem{
	width: 33.33%;
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	line-height: 28px;
	color: #01071A;
	display: flex;
	justify-content: center;
}
.secureTabsBtn{
	width: fit-content;
	cursor: pointer;
	padding: 0 32px 20px;
}
.secureTabsBtn:hover{
	color: #3486FF;
	border-bottom: 1px solid #3486FF;
}
.secureTabsItem_a{
	color: #3486FF;
	border-bottom: 1px solid #3486FF;
}
.secureTabs{
	width: 1440px;
	display: flex;
}
.secureTabsContent1{
	width: 1440px;
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
}
.secureTabsContent1L{
	width: 566px;
}
.secureTabsContent1LTitleM{
	font-size: 22px;
	font-weight: 500;
	color: #01071A;
	line-height: 30px;
	margin-bottom: 24px;
}
.secureTabsContent1LTitleS{
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: 500;
	color: #01071A;
	line-height: 28px;
	margin-bottom: 16px;
}
.secureTabsContent1LTitleSIcon{
	width: 22px;
	height: 22px;
	margin-right: 11px;

}
.secureTabsContent1LText{
	font-size: 14px;
	font-weight: 400;
	color: #01071A;
	line-height: 20px;
	margin-bottom: 32px;

}
.secureTabsContent1R{
	width: 791px;
	height: 795px;
}
.secureTabsContent1RImg{
	width: 100%;
}
.secureTabsContent2{
	width: 1440px;
	display: flex;
	justify-content: space-between;
	margin: 50px 0 48px; 
}
.secureTabsContent2Item{
	width: 696px;
	height: 809px;
	background: #FFFFFF;
	box-shadow: 0px 2px 36px 0px #EBF1F7;
	border-radius: 12px;
	padding: 40px 48px;
	box-sizing: border-box;
}
.secureTabsContent2ItemTitle{
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: 500;
	color: #01071A;
	line-height: 28px;
	margin-bottom: 16px;
}
.secureTabsContent2ItemIcon{
	width: 22px;
	height: 22px;
	margin-right: 9px;
}
.secureTabsContent2ItemText{
	font-size: 14px;
	font-weight: 400;
	color: #01071A;
	line-height: 20px;
}
.secureTabsContent2ItemImg{
	margin-top: 25px;
	width: 600px;
	height: 560px;
}
.secureTabsContent3{
	margin-top: 50px;
	display: flex;
	width: 1440px;
	justify-content: space-between;
	margin-bottom: 24px;
}
.secureTabsContent3Item{
	width: 696px;
	height: 441px;
	background: #FFFFFF;
	box-shadow: 0px 2px 36px 0px #EBF1F7;
	border-radius: 12px;
	padding: 32px 40px ;
	box-sizing: border-box;
	position: relative;
}
.secureTabsContent3ItemBg{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.secureTabsContent3Text{
	position: relative;
	z-index: 2;
}
.secureTabsContent3ItemTitle{
	display: flex;
	align-items: center;
	font-size: 24px;
	font-weight: 500;
	color: #3486FF;
	line-height: 33px;
	margin-bottom: 26px;
}
.secureTabsContent3TextRow{
	font-size: 16px;
	font-weight: 400;
	color: #01071A;
	line-height: 22px;
	display: flex;
	align-items: center;
	margin-bottom: 18px;
}
.secureTabsContent3ItemIcon{
	width: 40px;
	height: 40px;
	margin-right: 12px;
}
.secureTabsContent2ItemEn{
	height: 830px;
}
</style>
  
  