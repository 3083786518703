// import Vue from 'vue'
// import VueRouter from 'vue-router'

// Vue.use(VueRouter)


// const routes = [
  
//   // {
//   //   path: '/',
//   //   redirect:'/home'
//   // },
//   {
//     path: '/',
//     component: () => import('@/layout'),
//   },
//   {
//     path:'/home',
//     name: 'Home',
//     component: () => import('@/layout'),
    
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]

// const router = new VueRouter({
//   mode: 'history',
//   routes
// })

// export default router

import Vue from "vue"
import Router from "vue-router"
 
Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location){
  return originalPush.call(this, location).catch(err => err)
}

import Home from "@/views/Home"
// import download from "@/views/download"
import downloadV2 from "@/views/downloadV2"

import contact from "@/views/Home/contact.vue"
import news from "@/views/Home/news.vue"
import newsDetails from "@/views/Home/newsDetails.vue"

import secureChat from "@/views/Home/secureChat.vue"
import about from "@/views/Home/about.vue"
import install from "@/views/Home/install.vue"
import installDetails from "@/views/Home/installDetails.vue"
import kefu from "@/views/Home/kefu.vue"


import HelloWorld from "@/components/HelloWorld"
 
//每次用户请求都需要创建一个新的router实例
//创建createRouter工厂函数
export default function createRouter() {
    //创建router实例
    return new Router({
        mode: "history",
        routes: [
            {
              path: '/',
              name: 'home',
              component: Home,
            },
            // // 下载
            // {
            //   path: '/download',
            //   name: 'download',
            //   component: download,
            // },
            // 下载
            {
              path: '/download',
              name: 'download',
              component: downloadV2,
            },
            // 首页
            {
              path: '/home',
              component: Home,
            },
            
            // 首页-聊天
            {
              path: '/chat',
              name: 'chat',
              component: contact,
            },
            // 首页-最新动态-首页
            {
              path: '/news',
              name: 'news',
              component: news,
            },
            // 首页-最新动态-详情
            {
              path: '/newsDetails',
              name: 'newsDetails',
              component: newsDetails,
            },
            // 首页-安全聊天
            {
              path: '/secureChat',
              name: 'secureChat',
              component: secureChat,
            },
            // 首页-关于事密达
            {
              path: '/about',
              name: 'about',
              component: about,
            },
            // 首页-安装教程
            {
              path: '/install',
              name: 'install',
              component: install,
            },
            // 首页-安装教程详情页
            {
              path: '/installDetails',
              name: 'installDetails',
              component: installDetails,
            },
            // 首页-安装教程详情页
            {
              path: '/kefu',
              name: 'kefu',
              component: kefu,
            },
            // 404
            {
              path: '*',
              redirect: '/',
            },
        ]
    })
}


