<template>
  <div>
    <div class="installBox" v-if="pageType==1">
        <el-image class="installBg" :src="require(`@/images/phone/installBg.png`)"></el-image>
        <div class="installCardBox">
          <div class="installCardItem"  v-for="i in 7 " :key="i">
            <el-image class="installIcon"  :class="i==7?'installIcon7':''" :src="require(`@/images/home2/tutorialIcon${i}.png`)"></el-image>
            <div class="cardName">{{ $t(`install.card${i}`) }}</div>
            <div class="cardTips">{{ $t(`install.cardtips${i}`) }}</div>
            <div class="cardBtn flex" @click="changePage(i)">了解详情</div>
          </div>
         <div class="installCardItem" style="opacity: 0;"></div>

        </div>

    </div>
    <div class="installImgBox"  v-if="pageType==2">
				<el-image class="installImg" :src="require(`@/images/home2/jiaocheng${imgIndex}.png`)"></el-image>
      
    </div>
  </div>
  
</template>
<script>
export default {
  data() {
    return {
      pageType:1,
      imgIndex:null,
    };
  },
  mounted() {},
  methods: {
    changePage(index){
      console.log(1111111111111111);
      this.pageType = 2
      this.imgIndex = index
      this.$nextTick(() => {
        window.scrollTo({ top: 0, });
			})
    },
  },
};
</script>
<style scoped>
.flex{
	display: flex;
	align-items: center;
	justify-content: center;
}
.installBox{
  position: relative;
  padding: 153px 4.27vw 36px;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 50vh;
}
.installBg{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 750px;
  top: 0;
}
.installCardBox{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  z-index: 2;
}
.installCardItem{
  width: 165px;
  height: 197px;
  background: #fff;
  box-shadow: 0px 0px 33px 0px #E3EFFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px;
}
.installIcon{
  width: 51px;
  height: 51px;
  margin-top: 24px;
  margin-bottom: 13px;
}
.installIcon7{
  width: 121px;
  height: 24px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.cardName{
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #01071A;
  line-height: 25px;
  margin-bottom: 3px;
}
.cardTips{
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8F8F96;
  line-height: 14px;
  margin-bottom: 12px;
}
.cardBtn{
  width: 106px;
  height: 32px;
  border-radius: 30px;
  border: 1px solid #3486FF;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3486FF;
  line-height: 14px;
}
</style>

  