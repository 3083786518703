import createApp from './main';

const { app, router, store } = createApp();

// 如果服务端渲染时有数据注入，则使用该数据初始化 Vuex store
if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__);
}

// 挂载 Vue 实例到 DOM
router.onReady(() => {
  app.$mount('#app');
});
