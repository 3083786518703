<template>
  <div>
    <div ref="bodyAnimation" :style="style"></div>
  </div>
</template>
   
<script>
import lottie from "lottie-web";
export default {
  name: "",
  props: {
    options: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: '200px',
    },
    height: {
      type: String,
      default: '200px',
    },
  },
  data() {
    return {
      style: {
        width: this.width ? `${this.width}` : "100%",
        height: this.height ? `${this.height}` : "100%",
        overflow: "hidden",
        margin: "0 auto",
      },
    };
  },
  mounted() {
    this.anim = lottie.loadAnimation({
      container: this.$refs.bodyAnimation, // the dom element that will contain the animation
      renderer: "svg",
      loop: this.options.loop !== false,
      autoplay: this.options.autoplay !== false,
      animationData: this.options.animationData,
      //   path: sheepJson // the path to the animation json
    });
    this.$emit("animCreated", this.anim);
  },
  methods: {},
};
</script>
   
   <style lang="scss" scoped>
</style>