<template>
  <div class="secureBox">
	<div class="secureTitle">
		事密达APP采用了哪些安全层级？
	</div>
	<div class="secureIconRow">
		<el-image class="secureIconRowBg" :src="require(`@/images/phone/secureIconRowbg.png`)"></el-image>
		<div class="secureIconRowTitle">
			<el-image class="secureIconRowTitleIcon" :src="require(`@/images/phone/secureIconRowTitleIcon1.png`)"></el-image>
			4大核心安全层级
		</div>
		<div class="secureIconRow1Bottom">
			<div class="secureIconRow1BottomItem">
				<div class="blueDot"></div>
				通道加密
			</div>
			<div class="secureIconRow1BottomItem">
				<div class="blueDot"></div>
				内容加密
			</div>
			<div class="secureIconRow1BottomItem">
				<div class="blueDot"></div>
				服务数据库安全
			</div>
			<div class="secureIconRow1BottomItem">
				<div class="blueDot"></div>
				密钥安全
			</div>
		</div>
	</div>
	<div class="secureTitle">
		事密达APP加密方式详细信息
	</div>
	<div class="secureSubTitle">
		通道加密采用了哪些加密算法？
	</div>
	<div class="secureSubSubTitle">
		通道加密
	</div>
	<div class="secureText">
		通道加密中使用到的 RSA, ECDHE, AES256_CBC, SHA256, SHA1等等(如下图)
	</div>
	<el-image class="secureImg" :src="require(`@/images/home2/secureChatImg1_zh.png`)"></el-image>
	<div class="secureIconTitle">
		<el-image class="secureIconTitleIcon" :src="require(`@/images/home2/secureChatIcon3.png`)"></el-image>
		步骤
	</div>
	<div class="secureContent">
		1. 客户端和服务器先产生随机数。
		<br>2. 服务器下发随机数。
		<br>3. 客户端用 RSA 对客户端随机数进行加密，并发送给服务器。
		<br>4. 服务器用 RSA 解密客户端随机数（使用 RSA 的目的是防止中间人攻击）。
		<br>5. 客户端，服务器用自己的随机数加上对方的随机数生成临时密钥 TempKey 和临时偏移量 TempIV (此时双方均持有相同的 TempKey和TempIV)。
		<br>6. 客户端服务器均使用 ECDHE 生成各自的公私钥对。
		<br>7. 客户端用 TempKey, TempIV 对自己的 ECDHE 公钥进行加密，并将密文发送给服务器。
		<br>8. 服务器收到客户端的 ECDHE 公钥密文，并解密。
		<br>9. 服务器使用 TempKey, TempIV 对自己的 ECDHE 公钥进行加密，并使用 RSA 对 ECDHE。
		<br>10. 服务器使用 ECDHE 算法，使用客户端 ECDHE 公钥明文+服务器 ECDHE 私钥明文生成ShareKey。
		<br>11. 客户端收到服务器的 ECDHE 公钥密文和签名，然后进行解密和验证签名，如果没有问题，就使用客户端 ECDHE 私钥明文+服务器ECDHE 公钥明文生成 ShareKey。
		<br>12. 服务器初始化各个参数，下发给客户端,参数包含：
		<br>• AuthKeyID： 服务器随机生成的客户端临时标识符
		<br>• MsgKey： 由 ShareKey, AuthkeyID, MsgID, SessionID, Salt, SeqNo 以及真实的消息内容相加后进行 Sha256 后的值本参数用于：
		<br>a. 进行防止数据篡改的验证
		<br>b. 与 ShareKey 一起使用，生成 Aes256 key, IV，用来对各条消息进行加密。
		<br>• MsgID：时间相关的消息 ID，用于防止重放和去重。
		<br>• SessionID：每次登录唯一的会话 ID。
		<br>• Salt：每次登录唯一的盐值。
		<br>• SeqNo：用于防止重放。
		<br>13. 后续每条消息均会有不同的 MsgID, SessionID, Salt, SeqNo 和真实的消息内容；这些变化的值会导致 MsgKey 每次都不同。 这个每次都 不同的 MsgKey 加上 ShareKey 可以为每一条消息生成对应的 32 字节密钥和偏移量。
		<br>14. ShareKey 会过期，过期后需要重新进行密钥协商。
	</div>
	<div class="secureSubSubTitle">
		内容加密详解
	</div>
	<div class="secureIconTitle">
		<el-image class="secureIconTitleIcon" :src="require(`@/images/home2/secureChatIcon6.png`)"></el-image>
		非对称加密 如下图：
	</div>
	<div class="secureContent">
		1. 用户生成 ECDHE 公私钥，保存在本地。
		<br>2. 将公钥发送到服务器，私钥保存在本地。
		<br>3. 用户登录，获取所有好友信息的更新，包含每个好友的公钥。
		<br>4. 发送消息，和接收消息时均采用自己的私钥+对方的公钥生成 ShareKey。
		<br>5. 使用 ShareKey 生成 AES256 的密钥，进行加密和解密。
	</div>
	<el-image class="secureImg" :src="require(`@/images/home2/secureChatImg2_zh.png`)"></el-image>
	<div class="secureIconTitle">
		<el-image class="secureIconTitleIcon" :src="require(`@/images/home2/secureChatIcon7.png`)"></el-image>
		双棘轮算法 如下图：
	</div>
	<div class="secureContent">
		Alice(A)发送的消息传送了她的新公钥。 最终，Bob(B)将收到以下消息之一，并执行第二个DH棘轮步骤； 在每个DH棘轮步骤中生成的DH 输出用于导出新的发送和接收链密钥。当各方轮流执行DH棘轮步骤时，他们轮流引入新的发送链，依此类推。
	</div>
	<el-image class="secureImg" :src="require(`@/images/home2/secureChatImg3_zh.png`)"></el-image>

	<div class="secureIconTitleBlue">
		<el-image class="secureIconTitleIconBlue" :src="require(`@/images/home2/secureChatIcon8.png`)"></el-image>
		服务器数据库安全
	</div>
	<div class="secureCard">
		<div class="secureCardRow">
			服务器上的数据库做了防止人为篡改的处理
		</div>
		<div class="secureCardRow">
			<div class="blueDotBox">
				<div class="blueDot"></div>
			</div>
			数据库有签名字段
		</div>
		<div class="secureCardRow">
			<div class="blueDotBox">
				<div class="blueDot"></div>
			</div>
			服务器上生成 ECDSA 公私钥
		</div>
		<div class="secureCardRow">
			<div class="blueDotBox">
				<div class="blueDot"></div>
			</div>
			服务器代码中对数据库敏感字段，比如：password, userID, friend 等做增删改操作时均通过 ECDSA 生成签名，并更新到签名字段
		</div>
		<div class="secureCardRow">
			<div class="blueDotBox">
				<div class="blueDot"></div>
			</div>
			服务器代码对 password, userID, friend 等数据进行读取，判断等操作时进行 ECDSA验证签名，只有通过验证才能进行后续流程，否则 给客户端报错
		</div>
		<div class="secureCardRow">
			<div class="blueDotBox">
				<div class="blueDot"></div>
			</div>
			服务端程序进行加密和签名保护
		</div>
		<div class="secureCardBottom">
			<div class="secureCardBottomGreen"></div>
			<div class="secureCardBottomBule"></div>
		</div>
	</div>

	<div class="secureIconTitleBlue">
		<el-image class="secureIconTitleIconBlue" :src="require(`@/images/home2/secureChatIcon9.png`)"></el-image>
		密钥安全
	</div>
	<div class="secureCard">
		<div class="secureCardRow">
			<div class="blueDotBox">
				<div class="blueDot"></div>
			</div>
			用户的登录密码，二次密码，自动登录密码，均采用 SRP 算法，服务器上不存储用户的密码。
		</div>
		<div class="secureCardRow">
			<div class="blueDotBox">
				<div class="blueDot"></div>
			</div>
			用户可随时在客户端上手动更新内容加密的密钥
		</div>
		<div class="secureCardRow">
			<div class="blueDotBox">
				<div class="blueDot"></div>
			</div>
			用户的加密私钥仅放在用户手机
		</div>


		<div class="secureCardBottom">
			<div class="secureCardBottomGreen"></div>
			<div class="secureCardBottomBule"></div>
		</div>
	</div>
	<div class="secureTitle">
		什么是公钥和私钥？
	</div>
	<div class="secureIconRow">
		<el-image class="secureIconRowBg" :src="require(`@/images/phone/secureIconRowbg.png`)"></el-image>
		<div class="secureIconRowTitle">
			<el-image class="secureIconRowTitleIcon" :src="require(`@/images/home2/secureChatIcon4.png`)"></el-image>
			两大加密密钥
		</div>
		<div class="secureIconRow2Bottom">
			公开密钥（PubKey:简称公钥）和私有密钥（PriKey:简称私钥）。公钥与私钥是一对，如果用公钥对数据进行加密，只有用对应的私钥才能解密。 因为加密和解密使用的是两个不同的密钥，所以这种算法叫作非对称加密算法。如上文加密中使用的RSA、ECDHE为非对称加密算法
		</div>
	</div>

	<div class="secureTitle">
		非对称加密工作原理？
	</div>
	<div class="secureIconRow">
		<el-image class="secureIconRowBg" :src="require(`@/images/phone/secureIconRowbg.png`)"></el-image>
		<div class="secureIconRowTitle">
			<el-image class="secureIconRowTitleIcon" :src="require(`@/images/home2/secureChatIcon5.png`)"></el-image>
			加密工作原理
		</div>
		<div class="secureIconRow2Bottom">
			1. A要向B发送信息，A和B都要产生一对用于加密和解密的公钥和私钥。
			<br>2. A的私钥保密，A的公钥告诉B；B的私钥保密，B的公钥告诉A。
			<br>3. A要给B发送信息时，A用B的公钥加密信息，因为A知道B的公钥。
			<br>4. A将这个消息发给B（已经用B的公钥加密消息）。
			<br>5. B收到这个消息后，B用自己的私钥解密A的消息。其他所有收到这个数据的人都无法解密，因为只有B才有B的私钥。
		</div>
	</div>


  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  mounted() {},
  methods: {
	
  },
};
</script>
<style scoped>
.flex{
	display: flex;
	align-items: center;
	justify-content: center;
}
.secureBox{
	padding: 4.27vw;
	box-sizing: border-box;
}
.secureTitle{
	margin-bottom: 12px;
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #01071A;
	line-height: 22px;
}
.secureIconRow{
	padding: 4.27vw;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	background: #F6F8FA;
	border-radius: 6px;
	margin-bottom: 24px;
}
.secureIconRowBg{
	width: 152px;
	height: 158px;
	position: absolute;
	right: 0;
	bottom: 0;
}
.secureIconRowTitle{
	font-size: 14px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #01071A;
	line-height: 20px;
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}
.secureIconRowTitleIcon{
	width: 20px;
	height: 20px;
	margin-right: 8px;
}
.secureIconRow1Bottom,.secureIconRow1BottomItem{
	display: flex;
	align-items: center;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 17px;
	white-space: nowrap;
}
.blueDot{
	width: 4px;
	height: 4px;
	background: #3486FF;
	border-radius: 60px;
	margin-right: 4px;
	
}
.secureIconRow1BottomItem{
	margin-right: 16px;
}
.secureSubTitle{
	font-size: 14px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #01071A;
	line-height: 20px;
	margin-bottom: 12px;
}
.secureSubSubTitle{
	margin-bottom: 8px;
	font-size: 14px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #3486FF;
	line-height: 20px;
}
.secureText{
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 17px;
	margin-bottom: 12px;
}
.secureImg{
	width: 100%;
	margin-bottom: 18px;
}
.secureIconTitle{
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 20px;
	margin-bottom: 10px;
}
.secureIconTitleIcon{
	width: 16px;
	height: 16px;
	margin-right: 4px;
}
.secureContent{
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 17px;
	margin-bottom: 16px;
}
.secureIconTitleBlue{
	font-size: 14px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #3486FF;
	line-height: 20px;
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}
.secureIconTitleIconBlue{
	width: 20px;
	height: 20px;
	margin-right: 8px;
}
.secureCard{
	background: #FFFFFF;
	box-shadow: 0px 2px 18px 0px #EBF1F7;
	border-radius: 6px;
	overflow: hidden;
	padding: 18px 16px 2px;
	position: relative;
	box-sizing: border-box;
	margin-bottom: 24px;
}
.secureCardBottom{
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	display: flex;
}
.secureCardBottomGreen,.secureCardBottomBule{
	width: 33%;
	height: 4px;
	background: #42E7DB;
}
.secureCardBottomBule{
	width: 68%;
	background: #3486FF;
	border-radius: 2px;

}
.secureCardRow{
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 17px;
	display: flex;
	margin-bottom: 16px;
}
.blueDotBox{
	height: 17px;
	display: flex;
	align-items: center;
}
.secureIconRow2Bottom{
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 17px;
}
</style>

  