<template>
	<div class="header" :class="bgW==1?'navColorW':bgW==2?'navColorNo':allColorW?'allColorW':''">
		<div class="headerRow" :class="outerWidth<1500?'headerRowAutoPadding':''">
			<div class="headerRowL">
				<router-link :to="{name:'home'}" class="headerRowLogoBox" >
					<el-image class="headerRowLogoImg" alt="事密达App 专注保护用户聊天信息安全" :src="require('@/images/home2/navLogo.png')"></el-image>
					<div class="headerRowLogoText">
						{{ $t('header.shimida') }}
					</div>
				</router-link>
				<div class="headerRowBtnBox">
					<!-- <div class="navBtn navBtn_A" v-if="activeName=='1'">首页</div>
					<router-link class="navBtn" :to="{name:'home'}" v-else>首页</router-link>
					<div class="navBtn navBtn_A" v-if="activeName=='2'">轻聊网页版</div>
					<router-link class="navBtn" :to="{name:'chat'}" v-else>轻聊网页版</router-link>
					<div class="navBtn navBtn_A" v-if="activeName=='3'">安全聊天</div>
					<router-link class="navBtn" :to="{name:'join'}" v-else>安全聊天</router-link>
					<div class="navBtn navBtn_A" v-if="activeName=='4'">最新动态</div>
					<router-link class="navBtn" :to="{name:'chat'}" v-else>最新动态</router-link>
					<div class="navBtn navBtn_A" v-if="activeName=='5'">关于事密达</div>
					<router-link class="navBtn" :to="{name:'chat'}" v-else>关于事密达</router-link>
					<div class="navBtn navBtn_A" v-if="activeName=='6'">安装教程</div>
					<router-link class="navBtn navBtnAnima" :to="{name:'chat'}" v-on:mouseenter="showTooltip(6)" v-on:mouseleave="hideTooltip(6)" v-else>安装教程</router-link> -->

					<div v-for="(item , index) in toolbarData" :key="index">
						<div class="navBtn navBtn_A" v-if="activeName==item.activeName">{{ $t(`header.tag${index+1}`) }}</div>
						<router-link class="navBtn" :class="item.tapStatus==1?'navBtnAnimaIn':item.tapStatus==2?'navBtnAnimaOut':''" :to="{name:item.pageTo}" @mouseenter.native="showTooltip(index)" @mouseleave.native="hideTooltip(index)" v-else>{{ $t(`header.tag${index+1}`) }}</router-link>
					</div>
				</div>

			</div>
			<div class="headerRowR">
				<div class="headerRowRLanguageBtn" @click="clickLangbox()" @mouseenter="mouseenter()" @mouseleave="mouseleave()" >
					<!-- @click="testChangeLang" -->
					<div>{{ $t('header.lang') }}</div>
					<el-image class="headerRowRLanguageBtnImg" alt="多语言，简约中文，英语，法语，德语，日语，韩语...." :src="require('@/images/home2/languageBtn.png')"></el-image>
					<div class="languageSelect" v-if="showLangbox||showLangboxA">
						<div class="languageBtn" @click.stop="changeLanguage('zh')">{{ $t('header.zh') }}</div>
						<div class="languageBtn" @click.stop="changeLanguage('en')">{{ $t('header.en') }}</div>

					</div>
				</div>
				<router-link :to="{name:'kefu'}" class="headerRowRKfBtn displayCenter">
					{{ $t('header.kefu') }}
				</router-link>

			</div>

		</div>
	</div>
</template>
   
<script>
export default {
	name: "HomeHeader",
	props: {
		index: {
			type: String,
			default: '1'
		},
		topColorW: {
			type: Boolean,
			default: false
		},
		allColorW: {
			type: Boolean,
			default: false
		},
	}, 
	data() {
		return {
			activeName: this.index,
			outerWidth:0 ,
			toolbarData:[
				{
					activeName:1,
					pageTo:'home',
					tapStatus:0,
				},
				{
					activeName:2,
					pageTo:'chat',
					tapStatus:0,
				},
				{
					activeName:3,
					pageTo:'secureChat',
					tapStatus:0,
				},
				{
					activeName:4,
					pageTo:'news',
					tapStatus:0,
				},
				{
					activeName:5,
					pageTo:'about',
					tapStatus:0,
				},
				{
					activeName:6,
					pageTo:'install',
					tapStatus:0,
				},
			],
			bgW:0,
			showLangbox:false,
			showLangboxA:false,

		}
	},
	watch:{
		topColorW(newMessage, oldMessage) {
			// newMessage 是新值，oldMessage 是旧值
			// console.log('message 发生变化');
			// console.log('新值:', newMessage);
			// console.log('旧值:', oldMessage);
			if (newMessage) {
				this.bgW=1
			}else{
				this.bgW=2
 			}
			// 在这里执行自定义逻辑，例如发送HTTP请求、更新其他数据等
		},
		'$i18n.locale'(newLocale, oldLocale) {
			// this.getI18n()
			sessionStorage.setItem('lang', this.$i18n.locale);
		},
	},
	mounted() {
		// console.log(window.outerWidth);
		this.outerWidth = window.outerWidth
		sessionStorage.setItem('lang', this.$i18n.locale);
	},
	methods: {
		changeLanguage(type){
			console.log(type);
			this.$i18n.locale = type
			this.showLangboxA = false
			this.showLangbox = false

		},
		clickLangbox(){
			this.showLangboxA = !this.showLangboxA
			this.showLangbox = false
		},
		mouseenter(){
			this.showLangbox = true
		},
		mouseleave(){
			this.showLangbox = false
			
		},
		
		
		testChangeLang(){
			if(this.$i18n.locale === 'zh'){   // 判断当前语言
				this.$i18n.locale = 'en'   // 设置当前语言
				this.$forceUpdate()
			} else {
				this.$i18n.locale = 'zh'
				this.$forceUpdate()

			}
		},
		showTooltip(i){
			this.toolbarData[i].tapStatus=1
		},
		hideTooltip(i){
			this.toolbarData[i].tapStatus=2
		},
		handleClick(tab, event) {
			// console.log(tab, event);
			// console.log(Number(tab.index) + 1);
			if (Number(tab.index) + 1==this.index) {
				return
			}
			if (this.activeName=='1') {
				this.$router.push({name:'home'})
			}else if (this.activeName=='2') {
				this.$router.push({name:'join'})
			}else{
				this.$router.push({name:'about'})

			}
			return
			let _that = this;
			// 节流
			if (!_that.timeOut) {
				_that.timeOut = setTimeout(() => {
					_that.timeOut = null;
					this.$emit("changPage", this.activeName)
				}, 300);
			} else {
			}
		},
		
	},
}
</script>
   
<style scoped >
.header{
	position: fixed !important;
	top: 0px;
    /* overflow: hidden; */
    min-width: 100vw;
    left: 0;
    z-index: 99;
    height: 10.8%;
}
.headerRow {
	display: flex;
	height: 100%;
	padding: 0 12.45vw;
	justify-content: space-between;
	align-items: center;
	min-width: 1100px;
	width: 100%;
	box-sizing: border-box;
	position: absolute;
	top: 0px;
	left: 50%;
	transform: translateX(-50%);
}
.headerRowAutoPadding{
	padding: 0 calc( 12.45vw - (1100px - 75.1vw )/2 );
}
.headerRowL,.headerRowR{
	display: flex;
	align-items: center;
}

.headerRowLogoBox{
	display: flex;
	align-items: center;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #091D5A;
	line-height: 33px;
	margin-right: 4.17vw;
	white-space: nowrap;
	
}
.headerRowLogoImg{
	width: 40px;
	height: 40px;
	margin-right: 8px;
}
.headerRowBtnBox{
	display: flex;
	
}
.navBtn,.headerRowRLanguageBtn{
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 400;
	color: #091D5A;
	line-height: 25px;
	margin-right: 2.92vw;
	white-space: nowrap;
	cursor: pointer;
}
.navBtn{
	height: 80px;
	display: flex;
	align-items: center;
}
.headerRowRLanguageBtn{
	display: flex;
	align-items: center;
	margin-right: 1.67vw;
	height: 65px;
	position: relative;
}
.languageSelect{
	width: 100%;
	border-top: 3px solid #3486FF;;
	background-color: #fff;
	min-height: 96;
	padding: 14px 0;
	position: absolute;
	left: 0;
	bottom: 0;
	transform: translateY(90%);
	box-shadow: 0px 0px 20px 0px rgba(0,22,61,0.07);
}
.languageBtn{
	width: 100%;
	text-align: center;
	padding: 6px 0;
}
.languageBtn:hover{
	/* background-color: #3486FF; */
	color: #3486FF;

}

.headerRowRLanguageBtnImg{
	width: 12px;
	height: 9px;
	margin-top: 5px;
	margin-left: 6px;
}
.headerRowRKfBtn{
	/* width: 80px; */
	padding:0 12px;
	height: 38px;
	background: #3486FF;
	border-radius: 6px;
	font-size: 14px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 20px;
	cursor: pointer;
}
.headerRowRKfBtn:hover{
	background: rgba(52, 134, 255, .8);

}

.displayCenter{
	display: flex;
	align-items: center;
	justify-content: center;
	white-space:nowrap;
}

.navBtn_A{
	cursor: pointer;
	color: #3486FF;
	position: relative;
}
.navBtn_A::after{
	content: '';
	width: 100%;
	height: 2px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 8px;
	background-color: #3486FF;
}
.navBtnAnimaIn,.navBtnAnimaOut{
	position: relative;
}
.navBtnAnimaIn{
	color: #3486FF;
}
.navBtnAnimaOut{
	color: #091D5A;
}
.navBtnAnimaIn::after{
	content: '';
	height: 2px;
	position: absolute;
	
	bottom: 8px;
	background-color: #3486FF;
	
	animation: navBtnAnimaIn .4s ease-out 0s forwards normal;
    /* Safari 与 Chrome: */
    -webkit-animation: navBtnAnimaIn .4s ease-out 0s forwards normal;
}

@keyframes navBtnAnimaIn
{
		
    0%   { width: 0; left: 0; }
    100% { left: 0; width: 100%; }
}
 
@-webkit-keyframes navBtnAnimaIn /* Safari 与 Chrome */
{
    0%   { width: 0; left: 0; }
    100% { left: 0; width: 100%; }
}
.navBtnAnimaOut::after{
	content: '';
	height: 2px;
	position: absolute;
	
	bottom: 8px;
	background-color: #3486FF;
	
	animation: navBtnAnimaOut .4s ease-out 0s forwards normal;
    /* Safari 与 Chrome: */
    -webkit-animation: navBtnAnimaOut .4s ease-out 0s forwards normal;
}

@keyframes navBtnAnimaOut
{
    0%   {
		width: 100%;
		right: 0;
	}
    
    100% {
		right: 0;
		width: 0%;
	}
}
 
@-webkit-keyframes navBtnAnimaOut /* Safari 与 Chrome */
{
    0%   {
		width: 100%;
		right: 0;
		color: #091D5A;

	}
    
    100% {
		right: 0;
		width: 0%;
		color: #3486FF;

	}
}
.header{
	/* background-color: #fff; */
}
.navColorW{
	color: #3486FF;
	animation: navColorW .3s ease-out 0s forwards normal;
    /* Safari 与 Chrome: */
    -webkit-animation: navColorW .3s ease-out 0s forwards normal;
}
.navColorNo{
	color: #091D5A;
	animation: navColorNo .3s ease-in 0s forwards normal;
    /* Safari 与 Chrome: */
    -webkit-animation: navColorNo .3s ease-in 0s forwards normal;
}
@keyframes navColorW
{
		
    0%   { background-color: transparent; }
    100% { background-color: #fff; }
}
 
@-webkit-keyframes navColorW /* Safari 与 Chrome */
{
    0%   { background-color: transparent; }
    100% { background-color: #fff; }
}
@keyframes navColorNo
{
		
    0%   { background-color: #fff; }
    100% { background-color: transparent; }
}
 
@-webkit-keyframes navColorNo /* Safari 与 Chrome */
{
    0%   { background-color: #fff; }
    100% { background-color: transparent; }
}
.allColorW{
	background-color: #fff;
}
</style>
