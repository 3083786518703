<template>
	<div style="overflow: hidden;">
		<div class="pcBox">
			<home-header index="5"  :allColorW="true"></home-header>
			<div class="homeContent">
				<div class="navRow">
					<div class="navItem" @click="activeName=1" :class="activeName==1?'navItem_a':''">{{$t('about.fazhanlicheng')}}</div>
					<div style="width: 40px;border-bottom: 1px solid #EBEBEB;"></div>
					<div class="navItem" @click="activeName=2" :class="activeName==2?'navItem_a':''">{{$t('about.help')}}</div>
				</div>
				<div class="maoBox" v-if="activeName==1">
					<el-image class="navItem1Img" :src="require(`@/images/home2/aboutImg1${'_'+lang}.png`)"></el-image>
				</div>
				<div class="helpbox" v-if="activeName==2">
					<div class="helpboxNav" v-if="questionList[0]">
						<div class="helpNavTitle">{{questionList[0].name}}</div>
						<!-- <div class="helpNavTitle">{{$t('about.changjianwenti')}}</div> -->
						<!-- <div class="helpNavItem helpNavItem2 helpNavItem_a">事密达是什么？</div> -->
						<div class="helpNavItem" @click="clickHelpNav(1,item.id)" :class="selectId==item.id?'helpNavItem_a':''" v-for="item in questionList[0].list" :key="item.id">{{item.title}}</div>
						<div class="helpNavTitle">{{$t('about.jiaocheng')}}</div>
						<div v-for="(item,index) in helpList" :key="item.id">
							<div class="helpNavItem" @click="clickHelpNav(2,index)" >
								{{item.name}}
								<el-image class="helpNavItemImg" v-if="showHelpIndex==index" :src="require('@/images/home2/helpMore2.png')"></el-image>
								<el-image class="helpNavItemImg" v-else :src="require('@/images/home2/helpMore1.png')"></el-image>
							</div>
							<template v-if="showHelpIndex==index">
								<div class="helpNavItem" :class="selectId==listItem.id?'helpNavItem_a':''" @click="clickHelpNav(3,listItem.id)"  v-for="listItem in item.list" :key="listItem.id">
									{{listItem.title}}
								</div>
							</template>
							
							
						</div>
						

					</div>
					<div class="helpContent" v-html="pageData"></div>
				</div>
			</div>
			<home-foot></home-foot>

		</div>
		<div class="mobileBox">
			<mobile-main :index="pageindex"></mobile-main>

		</div>
	</div>
</template>
<script>
import HomeHeader from "./components/HomeHeader"
import HomeFoot from "./components/HomeFoot"
import MobileMain from "./components/MobileMain"
import HomeNews from "./components/HomeNews"

import { getHelpList2,getHelpInfo } from '@/api/system'


export default {
	components: {
		HomeHeader, 
		HomeNews,
		HomeFoot,
		MobileMain
	},
	data() {
		return {
			pageindex:'1',
			activeName:1,
			questionList:[],
			helpList:[],
			selectId:'',
			pageData:'',
			showHelpIndex:-1,
			lang:this.$i18n.locale,

		}
	},
	mounted(){
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		this.getPageData()
		document.title = '关于事密达 - 事密达'
		
	},
	watch: {
		'$i18n.locale'(newLocale, oldLocale) {
		// 在语言切换时执行逻辑
			this.getPageData()
			if (this.selectId) {
				this.getHelpInfoBtn(this.selectId)
			}
			this.lang = this.$i18n.locale;
		},
	},
	methods:{
		clickHelpNav(type,id){
			if (type==1) {
				this.getHelpInfoBtn(id)
				this.selectId= id
			}else if (type==2) {
				if (this.showHelpIndex== id) {
					this.showHelpIndex= 99999
				}else{
					this.showHelpIndex= id
				}
			}else if (type==3) {
				this.getHelpInfoBtn(id)
				this.selectId= id
			}
		},
		getHelpInfoBtn(id){
			getHelpInfo({content_id:id}).then(res => {
				console.log(res);
				
				this.pageData =res.data.content
			})
		},
		getPageData(){
			getHelpList2().then(res => {
				console.log(res);
				this.questionList = res.data.normal
				this.helpList = res.data.use_course
				if (this.selectId=='') {
					this.clickHelpNav(1,res.data.normal[0].list[0].id)
				}

			})
		},
		handleClick(tab, event) {
			console.log(tab, event);
		},
		//获取url参数
		getQueryVariable(value) {
			var query = window.location.search.substring(1);
			query = query.split("&");

			var data = {};

			for (var i = 0; i < query.length; i++) {
				var item = query[i].split("=");
				data[item[0]] = item[1]
			}

			if (value) {
				return data[value] ? data[value] : '';
			}

			return data;
		},
	},
};

</script>
<style scoped>


.color {
	background-color: #2d3a4b;

}

/* 主区域 */
.main {

	/* 当内容过多时y轴出现滚动条 */
	/* background-color: red; */
}

.mobileBox{
	display: none;
}
@media screen and (max-width:750px) {
	.pcBox{
		display: none;
	}
	.mobileBox{
		display: block;
	}
}
.homeContent{
	padding-top: 10.8vh;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.navRow{
	width: 1440px;
	display: flex;
	margin-top: 31px;
	margin-bottom: 24px;
	

}
.navItem{
	padding: 16px 0;
	font-size: 20px;
	font-weight: 600;
	color: #01071A;
	line-height: 28px;
	cursor: pointer;
	border-bottom: 1px solid #EBEBEB;
}
.navItem_a{
	color: #3486FF;
	border-bottom: 2px solid #3486FF;
}
.navItem1Img{
	width: 1445px;
}
.helpbox{
	width: 1440px;
	display: flex;
	justify-content: space-between;
}
.helpboxNav{
	width: 316px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 120px;
	max-height: 65vh;
    overflow-y: scroll;
    overflow-x: hidden;

}
.helpboxNav::-webkit-scrollbar {
	width: 5px !important;
	height: 5px !important;
}


.helpboxNav::-webkit-scrollbar-thumb {
	border-radius: 3px !important;
	-moz-border-radius: 3px !important;
	-webkit-border-radius: 3px !important;
	background-color: #E9EAF1 !important;
}

.helpboxNav::-webkit-scrollbar-track {
	background-color: transparent !important;
}


.helpNavTitle{
	font-size: 18px;
	font-weight: 600;
	color: #01071A;
	line-height: 25px;
	margin: 24px 0;
}
.helpNavItem{
	width: 300px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 400;
	color: #01071A;
	line-height: 25px;
	cursor:pointer;
	flex-shrink: 0;
}
.helpNavItem:hover{
	background: #EFF5FF;
}
.helpNavItem_a{
	color: #3486FF;
	background: #EFF5FF;
}
.helpContent{
	width: 1084px;
	margin-bottom: 90px;
}
.helpNavItemImg{
	width:12px;
	height:12px;
	margin-left:5px;
}
.maoBox{
	padding-bottom:120px;
}
</style>
  
  