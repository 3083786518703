<template>
  <div>
    <div class="homeSwiperBox">
		<van-swipe class="my-swipe" :autoplay="3000" >
			<van-swipe-item>
				<div class="swiperImgBox">
					<el-image class="swiperImg" :src="require(`@/images/phone/homeSw1.png`)"></el-image>
				</div>
			</van-swipe-item>
			<van-swipe-item>
				<div class="swiperImgBox">
					<el-image class="swiperImg" :src="require(`@/images/phone/homeSw2.png`)"></el-image>
				</div>
			</van-swipe-item>
		</van-swipe>
    </div>
	<div class="homeSafetyBox">
		<div class="homeSafetyTitle">全天守护 保障您的会话安全</div>
		<div class="homeSafetySub">保护用户信息(文本、图片、视频、语音、通话等)避免泄漏到他人查看</div>
		<div class="homeSafetyCardBox">
			<div class="homeSafetyCardItem" v-for="i in 9" :key="i">
				<el-image class="homeSafetyCardItemImg" :src="require(`@/images/home2/homeCard${i}.png`)"></el-image>
				<div class="homeSafetyCardItemTitle">{{ $t(`home.card${i}`) }}</div>
				<div class="homeSafetyCardItemTitleSub">{{ $t(`home.cardtips${i}`) }}</div>
				<div class="homeSafetyCardItemBtn flex" @click="gotoSecure"> 了解安全技术</div>
			</div>
			<div class="homeSafetyCardItem" style="opacity: 0;"></div>
		</div>
	</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  mounted() {},
  methods: {
	gotoSecure(){
		this.$emit('changePage', 2);
	},
  },
};
</script>
<style scoped>
.flex{
	display: flex;
	align-items: center;
	justify-content: center;
}
.swiperImgBox{
	width: 100vw;
	height: 348px;
	position: relative;
	overflow: hidden;
}
.swiperImg{
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 348px;
	width: 750px;
}
/deep/.van-swipe__indicator{
	width: 5px;
	height: 3px;
	border-radius: 2px !important;
	background: #B5BECC !important;
}
/deep/.van-swipe__indicator--active{
	width: 12px;
	background: #3486FF !important;
}
.homeSafetyBox{
	display: flex;
	align-items: center;
	flex-direction: column;
	padding:40px 4.27vw 48px;
	box-sizing: border-box;
}
.homeSafetyTitle{
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #01071A;
	line-height: 25px;
}
.homeSafetySub{
	font-size: 10px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	margin: 6px 0 16px;
	text-align: center;
	line-height: 17px;
}
.homeSafetyCardBox{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-around;
}
.homeSafetyCardItem{
	width: 167px;
	height: 228px;
	border-radius: 8px;
	border: 1px solid #E5E9EF;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 16px;
}
.homeSafetyCardItemImg{
	width: 57px;
	height: 63px;
	margin-top: 20px;
}
.homeSafetyCardItemTitle{
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #01071A;
	line-height: 22px;
}
.homeSafetyCardItemTitleSub{
	width: 140px;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8F8F96;
	line-height: 16px;
	margin-top: 6px;
	text-align: center;
}
.homeSafetyCardItemBtn{
	margin-top: auto;
	margin-bottom: 18px;
	width: 106px;
	height: 36px;
	border-radius: 60px;
	border: 1px solid #3486FF;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #3486FF;
	

}
</style>

  