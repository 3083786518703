<template>
	<div class="" >
		<div class="pcBox">
      <el-image class="pcBoxImg" :src="require('@/images/download/downloadImg1.png')"></el-image>
      <el-image class="pcBoxDwImg" @click="clickDw" id="downloadButton" :src="require('@/images/download/downloadBtn1.png')"></el-image>

      <div class="pcBoxinstall" v-for="i in 7" :key="i">
        <div class="titleRow" @click="changeRow(i)">
          
          <el-image class="titleRowImg" :src="require(`@/images/download/pingguo${i}@2x.png`)"></el-image>
          <el-image class="titleRowImg2" v-show="selectRow==i"  :src="require('@/images/download/ic_xiazai1@2x.png')"></el-image>
          <el-image class="titleRowImg2" v-show="selectRow!=i"  :src="require('@/images/download/ic_xiazai@2x.png')"></el-image>
        </div>
        <el-image class="installImg" v-show="selectRow==i" :src="require(`@/images/home2/jiaocheng${i}.png`)"></el-image>
      </div>
    </div>
    <div class="bottomRow">
      <div>产品功能：专注保护用户聊天信息安全</div>
      <div>当前版本: {{pageData.version}} | 大小 : {{pageData.filesize}}  </div>
      <div>开发者名称：广州友迅网络科技有限公司</div>
      <div>
        <a href="https://h5.smdchat.com/webRule?key=userAgreement" class="BottomRowRItem">《用户协议》</a>
        <a href="https://h5.smdchat.com/webRule?key=iosPrivacyPolicy" class="BottomRowRItem">《隐私协议》</a>
      </div>
    </div>
    <div class="toTopBtn" v-if="showTop" @click="toTopBtn">
      <el-image class="toTopBtnIc" :src="require(`@/images/download/ic_top.png`)"></el-image>
    </div>
    <div class="loading" v-if="showLoading">
      <van-loading type="spinner" color="#fff" />
    </div>
	</div>
</template>
<script src="https://g.alicdn.com/jssdk/u-link/index.min.js"></script>
<script>
import { getVersionInfo,recordLogger } from '@/api/system'
export default {
	
	data() {
		return {
			pageindex:'1',
      type:'android',
      downHrefIos:'',
      downHrefAndroid:'',
      pageData:{},
      selectRow:0,
      code:'',
			channel:'',
      showLoading:false,
      showTop:false,
		}
	},
  mounted(){
		document.body.scrollTop = document.documentElement.scrollTop = 0;
    let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      this.type = 'ios'
    }
    this.channel = this.getQueryVariable('channel')||sessionStorage.getItem('channel')
		this.code = this.getQueryVariable('code')||sessionStorage.getItem('code')
    this.getPageData()
		// this.initUlink()
    window.addEventListener("scroll", this.showIcon);
		document.title = '下载安装 - 事密达'

  },

	methods:{
    showIcon() {
			if (!!document.documentElement.scrollTop && document.documentElement.scrollTop > 5) {
				this.showTop = true
			} else {
				this.showTop = false
			}
		},
    clickDw(){
      this.showLoading = true
    },
    toTopBtn(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 使用平滑滚动效果
      });
    },
    loadULinkScript() {
      return new Promise((resolve, reject) => {
        // 创建一个 <script> 元素
        var script = document.createElement('script');
        // 设置脚本的源
        script.src = 'https://g.alicdn.com/jssdk/u-link/index.min.js';
        // 设置 onload 事件处理程序
        script.onload = resolve;
        // 设置 onerror 事件处理程序
        script.onerror = reject;
        // 将 <script> 元素添加到页面中
        document.head.appendChild(script);
      });
    },
		initUlink(id){
      let data = {
        code:this.code,
        channel:this.channel,
      }
      this.loadULinkScript().then(() => {
        // 在脚本加载完成后执行 ULink 的方法
        ULink({
          id,
          data,
          selector:"#downloadButton",
          // auto:true,
          timeout:2000,
          lazy:false,
          useOpenInBrowerTips: 'default',
          proxyOpenDownload:function (defaultAction, LinkInstance){
            if (LinkInstance.solution.type === "scheme"){
              // qq或者微信环境特殊处理下
              if (ULink.isWechat || ULink.isQQ) {
                // 在qq或者微信环境执行内置逻辑，具体内置逻辑为:当设置了useOpenInBrowerTips字段时，qq&&微信&&scheme时，启用蒙层提示去浏览器打开
                defaultAction();
              }else{
                window.location.href = LinkInstance.solution.downloadUrl;
              }
            }else if(LinkInstance.solution.type === "universalLink"){
            // universalLink 唤起应当由服务端提供一个带重定向到appstore的universallink地址。因此，此处不应写逻辑，友盟已于6月2日上线universalLink生成及重定向功能。
            }
          }
        })
        // 可以在这里调用其他 ULink 的方法
      }).catch(error => {
        console.error('Failed to load ULink SDK:', error);
      });
		},
    changeRow(i){
      if (i==this.selectRow) {
        this.selectRow=0
      }else{
        this.selectRow=i
      }
    },
    getPageData(){
      getVersionInfo({agent:"all"}).then(res => {
        // console.log(res);
        if (this.type=="ios") {
          this.pageData=res.data.ios
        }else{
          this.pageData=res.data.android

        }
        this.downHrefIos=res.data.ios.download_url
        this.downHrefAndroid=res.data.android.download_url
        this.initUlink(res.data.umeng_link_id)
      })
    },
    gotoDownLoadPage(type){
      if (this.type=='android') {
        window.location.href = this.downHrefAndroid
      }else if(this.type=='ios'){
        recordLogger({access_key :"h5_ios_download_click"}).then(res => {
          if (this.downHrefIos!="1") {
            window.location.href = this.downHrefIos
          }
        })
      }
		},
    gotohelp(){
			window.location.href = "https://h5.smdchat.com/ios_down?type=help"
    },

    //获取url参数
		getQueryVariable(value) {
			var query = window.location.search.substring(1);
			query = query.split("&");

			var data = {};

			for (var i = 0; i < query.length; i++) {
				var item = query[i].split("=");
				data[item[0]] = item[1]
			}

			if (value) {
				return data[value] ? data[value] : '';
			}

			return data;
		},
		changPage(e){
			// console.log(e);
			this.pageindex=e
		}
	},
};

</script>
<style>
body{
  margin: 0 !important;
}
</style>
<style scoped>
.pcBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2.18vh;
}
.pcBoxImg{
  width: 26vw;
  min-width: 350px;
  max-width: 497px;
  margin-bottom: 1vh;
}

.pcBoxDwImg{
  width: 18.4vw;
  min-width: 260px;
  max-width: 353px;
  margin-bottom: 2vh;
  cursor: pointer;

}
.pcBoxinstall{

}
.titleRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 23.5vw;
  max-width: 450px;
  min-width: 343px;
  cursor: pointer;
}
.titleRowImg{
  width: 15vw;
  max-width: 290px;
  min-width: 221px;
  margin-bottom: 20px;
}
.titleRowImg2{
  width: 1vh;
  max-width: 14px;
  min-width: 10px;
  /* margin-top: 20%; */
  transform: translateY(0%);
}
.installImg{
  margin-bottom: 20px;
  width: 23.5vw;
  max-width: 450px;
  min-width: 343px;
}
.bottomRow{
  width: 100vw;
  height: 10.37vh;
  max-height: 152px;
  min-height: 96px;
  background: #F6F6F6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  margin-top: 16px;
}
.BottomRowRItem{
  color: #3486FF;
  cursor: pointer;
  white-space: nowrap;
}
.toTopBtn{
  position: fixed;
  right: 11px;
  top: 70%;
  display: none;
}
.toTopBtnIc{
  width: 57px;
  height: 57px;
}
@media screen and (max-width:750px) {
	.toTopBtn{
		display: flex !important;
	}
	
}
.loading{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
  
  