<template>
	<div style="overflow: hidden;">
		<div class="pcBox">
			<home-header index="4"  :allColorW="true"></home-header>
			<div class="homeContent" v-if="pageData.title">
				<div class="homeRouter"><router-link :to="{name:'news', query: { pageType }}" class="homeRouterBtn">{{ pageData.type_name }}</router-link> > {{ $t(`HomeNews.dongtaixiangq`) }}</div>
				<div class="homeTitle">{{pageData.title}}</div>
				<div class="homeTime">{{changeTime(pageData.created_at)}}</div>
				<div class="homeRichText" v-html="pageData.content"> </div>
			</div>
			<div class="homeContent" v-else>
			</div>

			<home-foot></home-foot>

		</div>
		<div class="mobileBox">
			<mobile-main :index="pageindex"></mobile-main>

		</div>
	</div>
</template>
<script>
import HomeHeader from "./components/HomeHeader"
import HomeFoot from "./components/HomeFoot"
import MobileMain from "./components/MobileMain"
import HomeNews from "./components/HomeNews"


import { getHelpInfo } from '@/api/system'


export default {
	components: {
		HomeHeader, 
		HomeNews,
		HomeFoot,
		MobileMain
	},
	data() {
		return {
			pageindex:'1',
			pageType:1,
			// id:0,
			pagename:'',
			pageData:{},
		}
	},
	mounted(){
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		// this.id = this.getQueryVariable('id')
		// this.active = JSON.parse(this.getQueryVariable('active'))
		// console.log(this.id );
		// console.log(this.active );

		this.pagename = decodeURIComponent(this.getQueryVariable('name')) 
		this.pageType = this.getQueryVariable('pageType')
		console.log(this.pagename);

		this.getPageData(this.getQueryVariable('id'))
		

	},
	watch: {
		'$i18n.locale'(newLocale, oldLocale) {
		// 在语言切换时执行逻辑
			// this.getPageData(this.getQueryVariable('id'))
			this.$router.push({name:'news'})

		},
	},
	methods:{
		changeTime(time){
			const date = new Date(time*1000);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const seconds = String(date.getSeconds()).padStart(2, '0');
			return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		},
		getPageData(id){
			getHelpInfo({content_id:id}).then(res => {
				console.log(res);
				
				this.pageData =res.data
			})
		},
		//获取url参数
		getQueryVariable(value) {
			var query = window.location.search.substring(1);
			query = query.split("&");

			var data = {};

			for (var i = 0; i < query.length; i++) {
				var item = query[i].split("=");
				data[item[0]] = item[1]
			}

			if (value) {
				return data[value] ? data[value] : '';
			}

			return data;
		},
	},
};

</script>
<style scoped>
/* 头部样式 */


.color {
	background-color: #2d3a4b;

}

/* 主区域 */
.main {

	/* 当内容过多时y轴出现滚动条 */
	/* background-color: red; */
}

.mobileBox{
	display: none;
}
@media screen and (max-width:750px) {
	.pcBox{
		display: none;
	}
	.mobileBox{
		display: block;
	}
}
.homeContent{
	padding-top: 10.8vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-bottom: 140px;
	min-height:60vh;
}
.homeRouter{
	width: 1440px;
	margin: 24px 0 32px;
	font-size: 12px;
	font-weight: 400;
	color: #01071A;
	line-height: 17px;
}
.homeTitle{
	width: 1440px;
	font-size: 32px;
	font-weight: 600;
	color: #01071A;
	line-height: 45px;
}
.homeTime{
	width: 1440px;
	margin: 16px 0 32px;
	font-size: 12px;
	font-weight: 400;
	color: #8F8F96;
	line-height: 17px;
}
.homeRichText{
	width: 1440px;

}
.homeRouterBtn{
	width: fit-content;
	color: #01071A;
}
.homeRouterBtn:hover{
	color: #3486FF;
}

</style>
  
  