<template>
	<div>
		<div class="pcBox">
			<home-header index="7"  :allColorW="false"></home-header>
			<div class="homeContent">

				<div class="animaBgImg" ></div>

				<div class="iframeBox">
					<iframe v-if="language=='zh'" src="https://tb.53kf.com/code/client/03db608b8f8b2f31cad81111e7ffab441/1" frameborder="0" width="100%" height="100%"></iframe>
					<iframe v-else-if="language=='en'" src="https://tb.53kf.com/code/client/03db608b8f8b2f31cad81111e7ffab441/3" frameborder="0" width="100%" height="100%"></iframe>

					<!-- <iframe src="http://h5.debug.jinganquan.com/kf.html?arg=10723121&style=1&kflist=off&kf=1&zdkf_type=1&lnk_overflow=0&callback_id6ds=&language=cn&charset=GBK&referer=http%3A%2F%2Flocalhost%3A8080%2Fkefu&keyword=http%3A%2F%2Flocalhost%3A8080%2Fabout&tfrom=1&tpl=crystal_blue&uid=74aeb3889556d84051d7c29d726bc2fc&guest_id=15919077759003" frameborder="0" width="100%" height="100%"></iframe> -->

				</div>
			</div>


		</div>
		<div class="mobileBox">
			<mobile-main :index="pageindex"></mobile-main>

		</div>
	</div>
</template>
<script>
import HomeHeader from "./components/HomeHeader"
import HomeFoot from "./components/HomeFoot"
import MobileMain from "./components/MobileMain"

export default {
	components: {
		HomeHeader, 
		HomeFoot,
		MobileMain
	},
	data() {
		return {
			pageindex:'1',
			language:this.$i18n.locale,
		}
	},
	mounted(){
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		document.title = '联系客服 - 事密达'

	},
	watch: {
		'$i18n.locale'(newLocale, oldLocale) {
		// 在语言切换时执行逻辑
			this.language=this.$i18n.locale
			
		},
	},
	methods:{
		
	},
};

</script>
<style scoped>
/* 头部样式 */


.color {
	background-color: #2d3a4b;

}

/* 主区域 */
.main {

	/* 当内容过多时y轴出现滚动条 */
	/* background-color: red; */
}

.mobileBox{
	display: none;
}
@media screen and (max-width:750px) {
	.pcBox{
		display: none;
	}
	.mobileBox{
		display: block;
	}
}
.homeContent {
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	
}
.iframeBox{
	width: 800px;
	height: 600px;
	overflow: hidden;
	position: absolute;
	z-index: 2;
	border-radius: 16px;
	overflow: hidden;
}
.animaBgImg {
	width: 100%;
	height: 100vh;
	background-image: url('https://down.smdchat.com/common/www/bg_shouye1.png');
	background-color: rgb(226, 237, 254);

	background-position: top;
	background-repeat: no-repeat;
	background-size: auto 100vh;
	z-index: 0;
	position: absolute;
}
</style>
  
  