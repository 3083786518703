<template>
	<div class="homeContent">
		<div class="titleRow">
			<div class="titleRowL">
				<div class="navRow" v-if="tabData.length>0">
					<div class="navItem" @click="changeActiveName(tabData[0].id)" :class="tabData[0].id==activeId?'navItem_a':''">{{tabData[0].name}}</div>
					<div style="width: 40px;border-bottom: 1px solid #EBEBEB;"></div>
					<div class="navItem" @click="changeActiveName(tabData[1].id)" :class="tabData[1].id==activeId?'navItem_a':''">{{tabData[1].name}}</div>
				</div>
			</div>
			<div class="titleRowR">
				<el-input v-model="listData.keyword" clearable class="titleRowRInput" :placeholder="$t('HomeNews.sousuoneirong')"  />
				<div class="titleRowRshu"></div>
				<div class="titleRowRBtn" @click="getPageData">{{$t('HomeNews.sousuo')}}</div>

			</div>

		</div>
		<div class="msgBox" v-if="showList.length>0">
				<!-- <router-link :to="{name:'home'}" class="msgItem" v-for="i in 6"> -->
				<div  :to="{name:'home'}" class="msgItem" v-for="(item,index) in showList" :key='index'>
					<div class="msgItemL">
						<router-link :to="{name:'newsDetails', query: { id: item.id,name:active.name,pageType:active.id }}" class="msgItemLTitle">{{item.title}}</router-link>
						<router-link :to="{name:'newsDetails', query: { id: item.id,name:active.name,pageType:active.id }}" class="msgItemLContent">{{item.short_desc}}</router-link>
						<div class="msgItemLTime">{{item.created_at}}</div>
					</div>
					<div class="msgItemR">
						<el-image class="msgItemRImg" v-if="item.cover" :src="item.cover"></el-image>

					</div>
				</div>
				

		</div>
		<div v-else-if="showList.length==0" style="text-align:left; width:1440px;">{{$t('HomeNews.zanwu')}}</div>
	</div>
</template>


<script>
import { getHelpList } from '@/api/system'

export default {
	name: "HomeNews",
	props: {
		index: {
			type: String,
			default: '1'
		},
	},
	data() {
		return {
			channel: '',
			active:{},
			activeId:'',
			pageType:2,
			listData:{
				type_id:'',
				keyword:'',
			},
			tabData:[],
			showList:[],
		}
	},
	
	watch: {
		'$i18n.locale'(newLocale, oldLocale) {
		// 在语言切换时执行逻辑
			this.listData={
				type_id:'',
				keyword:'',
			}
			this.getPageData()
		},
	},
	mounted(){

		if (this.getQueryVariable('pageType')) {
			this.listData.type_id=this.getQueryVariable('pageType')
		}
		this.getPageData()

	},

	methods: {
		getQueryVariable(value) {
			var query = window.location.search.substring(1);
			query = query.split("&");
			var data = {};
			for (var i = 0; i < query.length; i++) {
				var item = query[i].split("=");
				data[item[0]] = item[1]
			}
			if (value) {
				return data[value] ? data[value] : '';
			}
			return data;
		},
		changeActiveName(id){
			this.activeId = id
			this.listData.type_id=id
			this.listData.keyword=''
			this.getPageData()

		},
		getPageData(){
			getHelpList(this.listData).then(res => {
				console.log(res);
				this.listData.type_id = this.activeId = res.type_id
				this.tabData = res.type_list
				this.showList = res.list
				if (res.type_id==res.type_list[0].id) {
					this.active = res.type_list[0]
				}else{
					this.active = res.type_list[1]
				}

			})
		},

		handleClick(tab, event) {
			console.log(tab, event);
		},
		getI18n(){
			
		},
		
		

		
		mouseenter() {

		},
		mouseleave() {

		},
		rollScroll(event) {
			let _that = this;
			// chrome、ie使用的wheelDelta，火狐使用detail
			let scrollVal = event.wheelDelta || event.detail;
			// 节流
			if (!_that.timeOut) {
				_that.timeOut = setTimeout(() => {
					_that.timeOut = null;
					scrollVal > 0
						? _that.$refs.carousel.prev()
						: _that.$refs.carousel.next();
				}, 300);
			} else {
			}
		},
		changeItem(e) {
			// console.log(e);
			if (e == 3) {
				return
			}
			let changPage = JSON.stringify(e + 1)
			let _that = this;
			_that.timeOut = setTimeout(() => {
				_that.timeOut = null;
				this.$emit("changPage", changPage)


			}, 300);
		},
	},

}
</script>


<style scoped>
	.homeContent{
		padding-top: 10.8vh;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-bottom: 105px;
	}
	.titleRow{
		width: 1440px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 13px;
	}
	.titleRowL{

	}
	.titleRowLTabBox{
		width: fit-content;
	}
	.titleRowLTabs{
		font-size: 20px;
		font-weight: 600;
		line-height: 28px;
	}
	.titleRowR{
		width: 240px;
		height: 40px;
		background: #F0F3F7;
		border-radius: 33px;
		padding: 9px 16px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;
	}
	.titleRowRInput{
		width: 157px;
		font-size: 16px;
		font-weight: 400;
		color: #8F8F96;
		line-height: 22px;
		background: #F0F3F7;
		height: 28px !important;

	}


	
	.titleRowRshu{
		width: 1px;
		height: 14px;
		background: #D8D8E0;
		margin-right: 10px;
	}
	.titleRowRBtn{
		font-size: 16px;
		font-weight: 400;
		color: #8F8F96;
		line-height: 30px;
		cursor: pointer;
		white-space: nowrap;
	}
	.msgBox{
		width: 1440px;
		min-height:70vh;
	}
	.msgItem{
		width: 100%;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #EBEBEB;
		padding: 24px 0;

	}
	.msgItemL{
		height: 160px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	
	}
	.msgItemLTitle{
		font-size: 20px;
		font-weight: 600;
		color: #01071A;
		line-height: 28px;
	}
	.msgItemLContent{
		font-size: 16px;
		font-weight: 400;
		color: #01071A;
		line-height: 22px;
		max-width: 1160px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}
	.msgItemLTime{
		font-size: 12px;
		font-weight: 400;
		color: #8F8F96;
		line-height: 17px;
	}
	.msgItemR{
		width: 240px;
		height: 160px;
	}
	.msgItemRImg{
		width: 240px;
		height: 160px;
	}
	.msgItem:hover{
		background: #F8F9FB;
	}
	.msgItemLTitle:hover,.msgItemLContent:hover{
		color: #3486FF;
		cursor: pointer;
	}
	.navRow{
		display: flex;
		margin-top: 31px;
		margin-bottom: 48px;
		
	}
	.navItem{
		padding: 16px 0;
		font-size: 20px;
		font-weight: 600;
		color: #01071A;
		line-height: 28px;
		cursor: pointer;
		border-bottom: 1px solid #EBEBEB;
	}
	.navItem_a{
		color: #3486FF;
		border-bottom: 2px solid #3486FF;
	}
</style>
<style>
	.titleRowR .el-input__inner{
		height: 30px !important;
		background: #F0F3F7;
		border: none;
	}
	.titleRowR .el-input__suffix{
		height: 30px !important;
		display: flex;
		align-items: center;
	}
</style>