<template>
	<div style="overflow: hidden;">
		<div class="pcBox">
			<home-header index="6"  :allColorW="true"></home-header>
			<div class="homeContent">
				<el-image class="installImg" :src="require(`@/images/home2/jiaocheng${pageType}.png`)"></el-image>
			</div>
			<home-foot></home-foot>

		</div>
		<div class="mobileBox">
			<mobile-main :index="pageindex"></mobile-main>

		</div>
	</div>
</template>
<script>
import HomeHeader from "./components/HomeHeader"
import HomeFoot from "./components/HomeFoot"
import MobileMain from "./components/MobileMain"
import HomeNews from "./components/HomeNews"


export default {
	components: {
		HomeHeader, 
		HomeNews,
		HomeFoot,
		MobileMain
	},
	data() {
		return {
			pageindex:'1',
			pageType:1,
		}
	},
	mounted(){
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		this.pageType = this.getQueryVariable('pageType')
		document.title = '安装教程 - 事密达'

	},
	methods:{
		//获取url参数
		getQueryVariable(value) {
			var query = window.location.search.substring(1);
			query = query.split("&");

			var data = {};

			for (var i = 0; i < query.length; i++) {
				var item = query[i].split("=");
				data[item[0]] = item[1]
			}

			if (value) {
				return data[value] ? data[value] : '';
			}

			return data;
		},
	},
};

</script>
<style scoped>
/* 头部样式 */


.color {
	background-color: #2d3a4b;

}

/* 主区域 */
.main {

	/* 当内容过多时y轴出现滚动条 */
	/* background-color: red; */
}

.mobileBox{
	display: none;
}
@media screen and (max-width:750px) {
	.pcBox{
		display: none;
	}
	.mobileBox{
		display: block;
	}
}
.homeContent{
	padding-top: 10.8vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-bottom: 140px;
	min-height: 100vh;
}
.installImg{
	margin-top: 56px;
	width: 750px;
}
</style>
  
  