<template>
	<div style="overflow: hidden;">
		<div class="pcBox">
			<home-header index="4"  :allColorW="true"></home-header>
			<home-news ></home-news>
			<home-foot></home-foot>

		</div>
		<div class="mobileBox">
			<mobile-main :index="pageindex"></mobile-main>

		</div>
	</div>
</template>
<script>
import HomeHeader from "./components/HomeHeader"
import HomeFoot from "./components/HomeFoot"
import MobileMain from "./components/MobileMain"
import HomeNews from "./components/HomeNews"


export default {
	components: {
		HomeHeader, 
		HomeNews,
		HomeFoot,
		MobileMain
	},
	data() {
		return {
			pageindex:'1',
		}
	},
	mounted(){
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		document.title = '最新动态 - 事密达'
	},
	methods:{
		
	},
};

</script>
<style scoped>
/* 头部样式 */


.color {
	background-color: #2d3a4b;

}

/* 主区域 */
.main {

	/* 当内容过多时y轴出现滚动条 */
	/* background-color: red; */
}

.mobileBox{
	display: none;
}
@media screen and (max-width:750px) {
	.pcBox{
		display: none;
	}
	.mobileBox{
		display: block;
	}
}

</style>
  
  