<template>
	<div style="overflow: hidden;">
		<div class="pcBox">
			<home-header index="2"  :allColorW="false"></home-header>
			<div class="iframeBox" >
				<iframe v-if="currentLanguage=='zh'" src="//chat.smdchat.com?language=zh" frameborder="0" width="100%" height="100%"></iframe>
				<iframe v-else-if="currentLanguage=='en'" src="//chat.smdchat.com?language=en" frameborder="0" width="100%" height="100%"></iframe>

				<!-- <iframe v-if="currentLanguage=='zh'" src="http://localhost:8081?language=zh" frameborder="0" width="100%" height="100%"></iframe>
				<iframe v-else-if="currentLanguage=='en'" src="http://localhost:8081?language=en" frameborder="0" width="100%" height="100%"></iframe> -->

			</div>


		</div>
		<div class="mobileBox">
			<mobile-main :index="pageindex"></mobile-main>

		</div>
	</div>
</template>
<script>
import HomeHeader from "./components/HomeHeader"
import HomeFoot from "./components/HomeFoot"
import MobileMain from "./components/MobileMain"

export default {
	components: {
		HomeHeader, 
		HomeFoot,
		MobileMain
	},
	data() {
		return {
			pageindex:'1',
			currentLanguage:'zh',
		}
	},
	watch: {
		'$i18n.locale'(newLocale, oldLocale) {
			// 在语言切换时执行逻辑
			// this.getI18n()
			this.currentLanguage = this.$i18n.locale;
		},
	},
	created() {
		// 获取当前语言状态
		const currentLanguage = this.$i18n.locale;
		console.log('当前语言:', currentLanguage);
		this.currentLanguage = currentLanguage
	},
	mounted(){
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		document.title = '轻聊 - 事密达'
	},
	methods:{
		
	},
};

</script>
<style scoped>


.color {
	background-color: #2d3a4b;

}

/* 主区域 */
.main {

	/* 当内容过多时y轴出现滚动条 */
	/* background-color: red; */
}

.mobileBox{
	display: none;
}
@media screen and (max-width:750px) {
	.pcBox{
		display: none;
	}
	.mobileBox{
		display: block;
	}
}

.iframeBox{
	width: 100%;
	height: 100vh;
	overflow: hidden;
}
</style>
  
  